import React from 'react';
import logo from '../assets/images/logo/gogroup.png';

const Footer = () => {
  return (
    <footer id="footer" className="footer">
      <div className="footer-newsletter">
        <div className="container">
          <div className="row">
            <div className="col-sm-12 col-md-12 col-lg-12 col-xl-3">
              <div className="footer__newsletter-text">
                <h6>Our Newsletter</h6>
                <p style={{color: '#9b9b9b'}}>Sign up for industry news & insights.</p>
              </div>
            </div>
            <div className="col-sm-12 col-md-12 col-lg-9 col-xl-7">
              <form className="footer__newsletter-form d-flex flex-wrap">
                <div className="form-group d-flex flex-1 mb-20 mr-1">
                  <input type="text" className="form-control" placeholder="Your Name" />
                  <input type="email" className="form-control" placeholder="Your Email Address" />
                </div>
                <button className="btn btn__primary btn__hover3 mb-20">Submit Now!</button>
              </form>
            </div>
            <div className="col-sm-12 col-md-12 col-lg-3 col-xl-2">
              <div className="social__icons justify-content-end">
                <a href="#"><i className="fa fa-facebook"></i></a>
                <a href="#"><i className="fa fa-twitter"></i></a>
                <a href="#"><i className="fa fa-instagram"></i></a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="footer-top">
        <div className="container">
          <div className="row">
            <div className="col-sm-12 col-md-12 col-lg-4 footer__widget footer__widget-about">
              <div className="footer__widget-content">
                <img src={logo} style={{ width: '13%' }} alt="logo" className="footer-logo" />
                <p style={{ color: '#9b9b9b'}}>Our global Go Groupcs expertise, advanced supply chain technology & customized Go Groupcs solutions
                  will help you develop and implement successful supply.</p>
                <ul className="contact__list list-unstyled">
                  <li><span>Email:</span><span>info@gogroup.me</span></li>
                  <li><span>Phone:</span><span>+971 52 344 9911</span></li>
                </ul>
              </div>
            </div>
            <div className="col-6 col-sm-6 col-md-3 col-lg-2 footer__widget footer__widget-nav">
              <h6 className="footer__widget-title">Who We Are</h6>
              <div className="footer__widget-content">
                <nav>
                  <ul className="list-unstyled">
                    <li><a href="#">About Us</a></li>
                    <li><a href="#">Meet Our Team</a></li>
                    <li><a href="#">News & Media</a></li>
                    <li><a href="#">Case Studies</a></li>
                    <li><a href="#">Contacts</a></li>
                    <li><a href="#">Careers</a></li>
                  </ul>
                </nav>
              </div>
            </div>
            <div className="col-6 col-sm-6 col-md-3 col-lg-2 footer__widget footer__widget-nav">
              <h6 className="footer__widget-title">What We Do</h6>
              <div className="footer__widget-content">
                <nav>
                  <ul className="list-unstyled">
                    <li><a href="#">Warehousing</a></li>
                    <li><a href="#">Air Freight</a></li>
                    <li><a href="#">Ocean Freight</a></li>
                    <li><a href="#">Road Freight</a></li>
                    <li><a href="#">Supply Chain</a></li>
                    <li><a href="#">Packaging</a></li>
                  </ul>
                </nav>
              </div>
            </div>
            <div className="col-6 col-sm-6 col-md-3 col-lg-2 footer__widget footer__widget-nav">
              <h6 className="footer__widget-title">Who We Serve</h6>
              <div className="footer__widget-content">
                <nav>
                  <ul className="list-unstyled">
                    <li><a href="#">Retail & Consumer</a></li>
                    <li><a href="#">Sciences & Healthcare</a></li>
                    <li><a href="#">Industrial & Chemical</a></li>
                    <li><a href="#">Power Generation</a></li>
                    <li><a href="#">Food & Beverage</a></li>
                    <li><a href="#">Oil & Gas</a></li>
                  </ul>
                </nav>
              </div>
            </div>
            <div className="col-6 col-sm-6 col-md-3 col-lg-2 footer__widget footer__widget-nav">
              <h6 className="footer__widget-title">Quick Links</h6>
              <div className="footer__widget-content">
                <nav>
                  <ul className="list-unstyled">
                    <li><a href="#">Request A Quote</a></li>
                    <li><a href="#">Track & Trace</a></li>
                    <li><a href="#">Find A Location</a></li>
                    <li><a href="#">Global Agents</a></li>
                    <li><a href="#">Help & FAQ</a></li>
                  </ul>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="footer-bottom">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-sm-12 col-md-6 col-lg-6">
              <div className="footer__copyright">
                <span>&copy; 2022-2024 Gogroup. All Rights Reserved</span>
              </div>
            </div>
            <div className="col-sm-12 col-md-6 col-lg-6">
              <nav>
                <ul className="list-unstyled footer__copyright-links d-flex flex-wrap justify-content-end">
                  <li><a href="#">Terms & Conditions </a></li>
                  <li><a href="#">Privacy Policy</a></li>
                  <li><a href="#">Sitemap</a></li>
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
