import React from 'react';
import unitedStatesFlag from '../assets/images/flags/united-states.png';
import germanyFlag from '../assets/images/flags/germany.png';
import uaeFlag from '../assets/images/flags/uae.png';

const ContactSection2 = () => {
  return (
    <section id="contact2" className="contact contact-2 text-center pt-0 pb-80 ">
      <div className="container">
        <div className="row">
          {/* Contact panel #1 */}
          <div className="col-sm-12 col-md-12 col-lg-12">
            <div className="contact-panel">
              <div className="contact__panel-header">
                <div className="contact__panel-icon">
                  <img src={uaeFlag} style={{ width: '20%' }} alt="uae" />
                </div>
                <h4 className="contact__panel-title">Dubai</h4>
              </div>
              <ul className="contact__list list-unstyled" style={{color: '#9b9b9b'}}>
                <li>+971 52 344 9911</li>
                <li>Email: <a href="mailto:info@gogroup.me">info@gogroup.me</a></li>
                <li>Address: Warehouse 7 Umm Ramool Behind dubai duty free, Dubai</li>
                <li>Hours: Mon-Fri: 8am – 7pm</li>
              </ul>
              <a href="#" className="btn btn__primary btn__hover3">
                <span>Contact Us</span><i className="icon-arrow-right"></i>
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ContactSection2;
