import React from 'react';
import project1Image from '../assets/images/blog/grid/1.jpg';
import project2Image from '../assets/images/blog/grid/2.jpg';
import project3Image from '../assets/images/blog/grid/3.jpg';

const BlogGrid = () => {
  return (
    <>
    <section id="blogGrid" className="blog blog-grid pb-60">
      <div className="container">
        <div className="row">
          <div className="col-sm-12 col-md-12 col-lg-6 offset-lg-3">
            <div className="heading text-center mb-50">
              <span className="heading__subtitle">Insight and Trends</span>
              <h2 className="heading__title">Recent Articles</h2>
              <p className="heading__desc" style={{color: '#9b9b9b'}}>Follow our latest news and thoughts which focuses exclusively on insight, industry trends, top news headlines.</p>
            </div>
          </div>
        </div>
        <div className="row">
          {/* Blog Item #1 */}
          <div className="col-sm-12 col-md-6 col-lg-4">
            <div className="blog-item">
              <div className="blog__img">
                <a href="#">
                  <img src={project1Image} alt="blog image" />
                </a>
              </div>
              <div className="blog__content">
                <div className="blog__meta">
                  <div className="blog__meta-cat">
                    <a href="#">Insights</a><a href="#">Management</a>
                  </div>
                </div>
                <h4 className="blog__title"><a href="#">Importers achieve cost savings through the First Sale rule!</a></h4>
                <span className="blog__meta-date">Jan 20, 2022</span>
                <p className="blog__desc" style={{color: '#9b9b9b'}}>The trade war currently ensuing between the US and several nations around the globe, most fiercely with China, shows no signs of the first set of tariffs levied against solar...</p>
                <a href="#" className="btn btn__link">
                  <span>Read More</span><i className="icon-arrow-right"></i>
                </a>
              </div>
            </div>
          </div>
          {/* Blog Item #2 */}
          <div className="col-sm-12 col-md-6 col-lg-4">
            <div className="blog-item">
              <div className="blog__img">
                <a href="#">
                  <img src={project2Image} alt="blog image" />
                </a>
              </div>
              <div className="blog__content">
                <div className="blog__meta">
                  <div className="blog__meta-cat">
                    <a href="#">Warehousing</a><a href="#">Industry</a>
                  </div>
                </div>
                <h4 className="blog__title"><a href="#">Cargo flow through better supply chain visibility, control.</a></h4>
                <span className="blog__meta-date">Oct 18, 2022</span>
                <p className="blog__desc" style={{color: '#9b9b9b'}}>Global provider connected products for consumers, and enterprises worldwide, supply chain control is everything, provide visibility and traceability needed for </p>
                <a href="#" className="btn btn__link">
                  <span>Read More</span><i className="icon-arrow-right"></i>
                </a>
              </div>
            </div>
          </div>
          {/* Blog Item #3 */}
          <div className="col-sm-12 col-md-6 col-lg-4">
            <div className="blog-item">
              <div className="blog__img">
                <a href="#">
                  <img src={project3Image} alt="blog image" />
                </a>
              </div>
              <div className="blog__content">
                <div className="blog__meta">
                  <div className="blog__meta-cat">
                    <a href="#">Logistics</a><a href="#">Distribution</a>
                  </div>
                </div>
                <h4 className="blog__title"><a href="#">Importance of specialized focus in Projects, Oil & Gas Logistics?</a></h4>
                <span className="blog__meta-date">Jan 20, 2022</span>
                <p className="blog__desc" style={{color: '#9b9b9b'}}>Our team provides highly skilled & experienced project managers who know the intricacies of this vertical and focus on providing innovative solutions in Oil & Gas sector...</p>
                <a href="#" className="btn btn__link">
                  <span>Read More</span><i className="icon-arrow-right"></i>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    </>
  );
};

export default BlogGrid;
