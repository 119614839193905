import React from 'react';

import slide1 from '../assets/images/sliders/1.jpg';
import slide2 from '../assets/images/sliders/5.jpg';
import slide3 from '../assets/images/sliders/3.jpg';
import slide4 from '../assets/images/sliders/4.jpg';
import OwlCarousel from 'react-owl-carousel';

import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
// import 'owl.carousel/dist/owl.carousel';

import { useEffect, useState } from 'react';
import Header from './Header';
import OurFeatureCollection from './OurFeature'






const  Slider = () => {
  const [scrollVisible, setScrollVisible] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
      if (scrollTop > 100) { // You can adjust this threshold as needed
        setScrollVisible(true);
      } else {
        setScrollVisible(false);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  
  
  
  useEffect(() => {
  document.querySelectorAll('.bg-img').forEach(function(element) {
    const imgElement = element.querySelector('img');
    if (imgElement) {
      const imgSrc = imgElement.getAttribute('src');
      element.parentNode.style.backgroundImage = `url(${imgSrc})`;
      element.parentNode.style.backgroundSize = 'cover';
      element.parentNode.style.backgroundPosition = 'center';
      element.parentNode.classList.add('bg-img');
      element.parentNode.removeChild(element);
    }
    
  });
  
}, []); 

const slides = [
  {
    title: 'Fast, Certified & Flexible Solutions.',
    description: 'Through integrated supply chain solutions, our drives sustainable competitive advantages to some of the largest companies all over the world.',
  },
  {
    title: 'Affordable Price & Great Solutions.',
    description: 'Through integrated supply chain solutions, our drives sustainable competitive advantages to some of the largest companies all over the world.',
  },
  {
    title: 'Solutions Match Your Needs.',
    description: 'Through integrated supply chain solutions, our drives sustainable competitive advantages to some of the largest companies all over the world.',
  },
  {
    title: 'Affordable Price, Certified Experts &',
    description: 'Through integrated supply chain solutions, our drives sustainable competitive advantages to some of the largest companies all over the world.',
  },
];

const dotsButton =[
  {
    title:'Warehousing <br /> Services',
    iClassname:'icon-forklift-1'
  },
  {
    title:'Air Freight<br /> Services',
    iClassname:'icon-air-freight'
  },
  {
    title:'Ocean Freight<br /> Services',
    iClassname:'icon-cargo-ship'
  },
  {
    title:'Road Freight<br /> Services',
    iClassname:"icon-truck"
  }
]

const options = {
  loop: true,
  center: true,
  items: 1,
  // margin: 0,
  autoplay: true,
  dots: true,
  dotsContainer	:'owl-thumb-item',
  autoplayTimeout: 8500,
  smartSpeed: 450,
  nav: true,
  thumbs: true,
  thumbsPrerendered: true,
  // navText: ['<i class="fa fa-chevron-left"></i>', '<i class="fa fa-chevron-right"></i>'],
};


  return (
    <>
    <div className="">
        <Header/>
    <section id="slider1" className="slider slider-1" >
    <OwlCarousel
    className="owl-carousel owl-theme thumbs-carousel carousel-arrows"
    data-slider-id="slider1"
    data-autoplay="true"
    data-nav="true"
    data-transition="fade"
    data-dots="false"
    data-animate-out="fadeOut"
    data-animate-in="fadeIn"
    {...options}
    thumbs={true}
    thumbContainerClass="owl-thumbs"
    thumbItemClass="owl-thumb-item"
  >
    {slides.map((slide, index) => (
      <div className="item slide-item align-v-h bg-overlay" key={index}>
        <div className="bg-img"><img src={index === 0 ? slide1 : index === 1 ? slide2 : index === 2 ? slide3 : slide4} alt="slide img" /></div>
        <div className="container">
          <div className="row">
            <div className="col-sm-12 col-md-12 col-lg-8">
              <div className="slide__content">
                <h2 className="slide__title">{slide.title}</h2>
                <p className="slide__desc">{slide.description}</p>
                <a href="#" className="btn btn__primary btn__hover2 mr-30">Our Services</a>
                <a href="#" className="btn btn__white">About Us</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    ))}
  </OwlCarousel>
        <div className="container">
          <div className="row">
            <div className="col-sm-12 col-md-12 col-lg-12 d-none d-lg-block">
              <div className="owl-thumbs thumbs-dots" data-slider-id="slider1"  {...options}>
                <button className="owl-thumb-item button-1">
                  <i className="icon-forklift-1"></i>
                  <span>Warehousing <br /> Services</span>
                </button>
                <button className="owl-thumb-item">
                  <i className="icon-air-freight"></i>
                  <span>Air Freight<br /> Services</span>
                </button>
                <button className="owl-thumb-item"> 
                  <i className="icon-cargo-ship"></i>
                  <span>Ocean Freight<br /> Services</span>
                </button>
                <button className="owl-thumb-item">
                  <i className="icon-truck"></i>
                  <span>Road Freight<br /> Services</span>
                </button>
              </div>
            </div> 

            <div className="col-sm-12 col-md-12 col-lg-12 d-none d-lg-block">
              <a className="btn btn__video btn__video-lg popup-video" href="https://www.youtube.com/watch?v=nrJtHemSPW4" target="_blank">
                <div className="video__player">
                  <span className="video__player-animation"></span>
                  <span className="video__player-animation video__player-animation-2"></span>
                  <i className="fa fa-play"></i>
                </div>
                <span className="video__btn-title">Watch Our Presentation!</span>
              </a>
            </div>
          </div>
        </div>
      </section>
      <section id="services" className="services pb-90">
      <div className="container">
        <div className="row heading heading-2 mb-40">
          <div className="col-sm-12 col-md-12 col-lg-12">
            <span className="heading__subtitle">Real Solutions, Real Fast!</span>
          </div>
          <div className="col-sm-12 col-md-12 col-lg-6">
            <h2 className="heading__title">Delivering the Best Global Logistics Solutions.</h2>
          </div>
          <div className="col-sm-12 col-md-12 col-lg-6">
            <p className="heading__desc" style={{color:'#9b9b9b'}}>Our global logistics expertise, advanced supply chain technology & customized
              logistics solutions will help you analyze, develop and implement successful supply chain management
              strategies.</p>
          </div>
        </div>
        <div className="row text-center">
          <div className="col-sm-6 col-md-6 col-lg-4">
            <div className="service-item">
              <div className="service__icon"><i className="icon-air-freight"></i></div>
              <div className="service__content">
                <h4 className="service__title">Air Freight</h4>
                <p className="service__desc" style={{color:'#9b9b9b'}}>We can arrange and provides with the comprehensive service in the sphere of
                  urgent, valuable, fragile or any cargoes conscientious accelerated delivery by air.</p>
                <a href="#" className="btn btn__white">
                  <span>Read More</span><i className="icon-arrow-right"></i>
                </a>
              </div>
            </div>
          </div>
          <div className="col-sm-6 col-md-6 col-lg-4">
            <div className="service-item">
              <div className="service__icon"><i className="icon-cargo-ship"></i></div>
              <div className="service__content">
                <h4 className="service__title">Ocean Freight</h4>
                <p className="service__desc" style={{color:'#9b9b9b'}}>We provides with the main types of basic conditions International sea
                  transportation is implemented by our partners’ vessels, the largest ocean carriers.</p>
                <a href="#" className="btn btn__white">
                  <span>Read More</span><i className="icon-arrow-right"></i>
                </a>
              </div>
            </div>
          </div>
          <div className="col-sm-6 col-md-6 col-lg-4">
            <div className="service-item">
              <div className="service__icon"><i className="icon-truck"></i></div>
              <div className="service__content">
                <h4 className="service__title">Road Freight</h4>
                <p className="service__desc" style={{color:'#9b9b9b'}}>We provides with the main types of basic conditions International sea
                  transportation is implemented by our partners’ vessels, the largest ocean carriers.</p>
                <a href="#" className="btn btn__white">
                  <span>Read More</span><i className="icon-arrow-right"></i>
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-12 col-md-12 col-lg-12 text-center">
            <p className="text__link mb-0">Logistic & Transport Solutions Saves Your Time <a href="#">Find your solution</a>
            </p>
          </div>
        </div>
      </div>
    </section>
    </div>
    <OurFeatureCollection></OurFeatureCollection>
    {scrollVisible && (
          <button id="scrollTopBtn" className='actived' onClick={scrollToTop}>
            <i className="fa fa-long-arrow-up"></i>
          </button>
        )}
    </>
  );
}

export default Slider;


