import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'; // Import Routes from v6
import Slider from './components/Slider';
import '../src/assets/css/style.css'; 
import './../node_modules/bootstrap/dist/css/bootstrap.min.css'
import '../src/assets/css/libraries.css';
import './assets/css/buttons.css';
import Contact from './components/Contact'

const App = () => {
  return (
    <>
      <Router>
        <div>
          <Routes>
            <Route exact path="/" element={<Slider />} /> {/* Use "element" prop for component */}
            <Route path="/contact" element={<Contact />} />
          </Routes>
        </div>
      </Router>
    </>
  );
}

export default App;
