import React, { useState, useEffect } from 'react';
import bannerImage from '../assets/images/banners/7.jpg';
import signatureImage from '../assets/images/about/singnture2.png';

const BannerSection = () => {
  const [clients, setClients] = useState(0);
  const [deliveredGoods, setDeliveredGoods] = useState(0);
  const [milesDriven, setMilesDriven] = useState(0);

  useEffect(() => {
    const animateCounters = () => {
      const interval = setInterval(() => {
        setClients(clients => {
          if (clients === 482) {
            clearInterval(interval);
            return 482;
          }
          return clients + 1;
        });
      }, 10);
      
      const interval2 = setInterval(() => {
        setDeliveredGoods(goods => {
          if (goods === 785) {
            clearInterval(interval2);
            return 785;
          }
          return goods + 1;
        });
      }, 8);
      
      const interval3 = setInterval(() => {
        setMilesDriven(miles => {
          if (miles === 1348) {
            clearInterval(interval3);
            return 1348;
          }
          return miles + 1;
        });
      }, 5);

      return () => {
        clearInterval(interval);
        clearInterval(interval2);
        clearInterval(interval3);
      };
    };

    animateCounters();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <section id="banner3" className="banner banner-3 p-0 bg-theme">
      <div className="container-fluid col-padding-0">
        <div className="row">
          <div className="col-sm-12 col-md-12 col-lg-6 background-banner bg-overlay">
            <div className="bg-img"><img src={bannerImage} alt="background" /></div>
            <div className="video__btn video__btn-white video__btn-right-center">
              <a className="popup-video" href="https://www.youtube.com/watch?4=&v=TKnufs85hXk" target="_blank">
                <span className="video__player-animation"></span>
                <span className="video__player-animation video__player-animation-2"></span>
                <div className="video__player">
                  <i className="fa fa-play"></i>
                </div>
              </a>
            </div>
          </div>
          <div className="col-sm-12 col-md-12 col-lg-6">
            <div className="inner-padding">
              <div className="heading heading-3 heading-white mb-50">
                <h2 className="heading__title">Flexible Pricing Policy <br/>& Decades Of Experience!</h2>
                <p className="heading__desc">We believe that the services, offered by our company, should satisfy the high
                  expectations of our customers. We are dedicated in creating added value for our customers by
                  implementing modern technology in our work. That is why the desire of constant improvement is the
                  driving force behind our transportation business.</p>
              </div>
              <div className="counters-white d-flex flex-wrap justify-content-between">
                <div className="counter-item">
                  <h4><span className="counter">{clients}</span><span>m</span></h4>
                  <p className="counter__desc">Clients Worldwide</p>
                </div>
                <div className="counter-item">
                  <h4><span className="counter">{deliveredGoods}</span><span>m</span></h4>
                  <p className="counter__desc">Delivered Goods</p>
                </div>
                <div className="counter-item">
                  <h4><span className="counter">{milesDriven}</span><span>m</span></h4>
                  <p className="counter__desc">Miles Driven</p>
                </div>
              </div>
              <p className="color-white mb-20">Providing the best transport and shipping services currently available
                allover the world. Our skilled personnel, utilising the latest communications, new tracking and
                processing software, combined with decades of experience!</p>
              <img src={signatureImage} alt="signature" />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default BannerSection;
