import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import '../assets/css/style.css';
import logoLight from '../assets/images/logo/gogroup.png';
import logoDark from '../assets/images/logo/gogroup.png'; 
import UnitedStates from '../assets/images/flags/united-states.png';
import Arebic from '../assets/images/flags/uae.png';
const Header = () => {
  const [isSticky, setIsSticky] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [isNavOpen, setIsNavOpen] = useState(false);

  const [subdropdownOpen, setSubDropdownOpen] = useState({
    company: false,
    services: false,
    features: false
  });

  const toggleSubDropdown = (dropdown) => {
    setSubDropdownOpen(prevState => ({
      ...prevState,
      [dropdown]: !prevState[dropdown]
    }));
  };
  

  useEffect(() => {
    const handleScroll = () => {
      if (window.innerWidth >= 992) {
        if (window.pageYOffset > 80) {
          setIsSticky(true);
        } else {
          setIsSticky(false);
        }
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const toggleNav = () => {
    setIsNavOpen(!isNavOpen);
  };

    // Global variables
  return (
    <header id="header" className={`header header-transparent ${isSticky ? 'fixed-navbar' : ''}`}>
      <nav className="navbar navbar-expand-lg sticky-navbar">
        <div className="container">
          <Link className="navbar-brand d-flex align-items-center" to={"/"}>
          <img src={logoLight} className="logo-light" alt="logo" />
            <img src={logoDark} className="logo-dark" alt="logo" />
            <h4 style={{ color: isSticky ? '#000' : '#fff' }}>Go Group</h4>
          </Link>
          <button className="navbar-toggler" type="button" onClick={toggleNav}>
            <span className="menu-lines"><span></span></span>
          </button>
          <div className={`collapse navbar-collapse ${isNavOpen ? 'menu-opened' : ''}`} id="mainNavigation">
            <ul className="navbar-nav ml-auto">
              <li className="nav__item with-dropdown">
                <a href="#" className="dropdown-toggle nav__item-link active">Home</a>
              </li>
              <li className="nav__item with-dropdown">
                 <a href="#" className="dropdown-toggle nav__item-link">Company</a> {/*about-us.php */}
                <i className={`fa ${subdropdownOpen.company ? 'fa-angle-down' : 'fa-angle-right'}`} data-toggle="dropdown"  onClick={() => toggleSubDropdown('company')}></i>
                <ul className={`dropdown-menu ${subdropdownOpen.company ? 'show' : ''}`}>
                  <li className="nav__item"><a href="#" className="nav__item-link">About Us</a></li>{/*about-us.php */}
                  <li className="nav__item"><a href="#" className="nav__item-link">Why Choose Us</a></li>{/*why-us.php*/}
                </ul>
              </li>
              <li className="nav__item with-dropdown">
                <a href="#" className="dropdown-toggle nav__item-link">Services</a>
                <i className={`fa ${subdropdownOpen.services ? 'fa-angle-down' : 'fa-angle-right'}`} data-toggle="dropdown" onClick={() => toggleSubDropdown('services')}></i>
                <ul className={` wide-dropdown-menu dropdown-menu ${subdropdownOpen.services ? 'show' : ''}`}>
                  <li className="nav__item">
                    <div className="row mx-0">
                      <div className="col-sm-6 dropdown-menu-col">
                        <h6>Transport Services</h6>
                        <ul className="nav flex-column">
                          <li className="nav__item"><a className="nav__item-link" href="#">Warehousing</a></li>
                          <li className="nav__item"><a className="nav__item-link" href="#">Air Freight</a></li>
                          <li className="nav__item"><a className="nav__item-link" href="#">Ocean Freight</a></li>
                          <li className="nav__item"><a className="nav__item-link" href="#">Road Freight</a></li>
                          <li className="nav__item"><a className="nav__item-link" href="#">Supply Chain</a></li>
                          <li className="nav__item"><a className="nav__item-link" href="#">Packaging</a></li>
                        </ul>
                      </div>
                      <div className="col-sm-6 dropdown-menu-col">
                        <h6>Industry Solutions</h6>
                        <ul className="nav flex-column">
                          <li className="nav__item"><a className="nav__item-link" href="#">Retail & Consumer</a></li>
                          <li className="nav__item"><a className="nav__item-link" href="#">Sciences & Healthcare</a></li>
                          <li className="nav__item"><a className="nav__item-link" href="#">Industrial & Chemical</a></li>
                          <li className="nav__item"><a className="nav__item-link" href="#">Power Generation</a></li>
                          <li className="nav__item"><a className="nav__item-link" href="#">Food & Beverage</a></li>
                          <li className="nav__item"><a className="nav__item-link" href="#">Oil & Gas</a></li>
                        </ul>
                      </div>
                    </div>
                  </li>
                </ul>
              </li>
              <li className="nav__item with-dropdown">
                <a href="#" className="dropdown-toggle nav__item-link">News & Media</a> {/*blog*/}
              </li>
              <li className="nav__item with-dropdown">
                <a href="#" data-toggle="dropdown" className="dropdown-toggle nav__item-link">Features</a>
                <i className={`fa ${subdropdownOpen.features ? 'fa-angle-down' : 'fa-angle-right'}`} data-toggle="dropdown" onClick={() => toggleSubDropdown('features')}></i>
                <ul className={`dropdown-menu ${subdropdownOpen.features ? 'show' : ''}`}>
                  <li className="nav__item"><a href="#" className="nav__item-link">request a quote</a></li> {/*request-quote.php */}
                  <li className="nav__item"><a href="#" className="nav__item-link">track and trace</a></li> {/*track-shipment.php */}
                  <li className="nav__item"><a href="#" className="nav__item-link">find Location</a></li> {/*find-location.php */}
                  <li className="nav__item"><a href="#" className="nav__item-link">Rates & Pricing</a></li> {/*rates.php */}
                  <li className="nav__item"><a href="" className="nav__item-link">help and faqs</a></li> {/*faqs.php */}
                </ul>
              </li>
              <li className="nav__item">
                <Link to="/contact" className="nav__item-link">Contacts</Link>
              </li>
            </ul>
          </div>
          <div className="navbar-modules">
            <ul className="modules__wrapper d-flex align-items-center list-unstyled">
              <li>
                <a href="#" className="module__btn module__btn-search"><i className="fa fa-search"></i></a>
              </li>
              <li className="d-none d-lg-block">
                <a href="#" className="module__btn btn__request btn">
                  <span>Request A Quote</span><i className="icon-arrow-right"></i>
                </a>
              </li>
              <li>
                <div className="dropdown">
                  <button className="module__btn dropdown-toggle" id="langDrobdown" data-toggle="dropdown" onClick={toggleDropdown}>
                    <span>En</span>
                  </button>
                  <div className={`dropdown-menu ${dropdownOpen ? 'show' : ''}`} aria-labelledby="langDropdown">
                    <a className="dropdown-item" href="#">
                      <img src={UnitedStates} alt="us" /><span>Us</span>
                    </a>
                    <a className="dropdown-item d-flex align-items-center" href="#">
                      <img src={Arebic} alt="germany" /><span>Arabic</span>
                    </a>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </header>
  );
}

export default Header;
