import React from 'react';
import { useState } from 'react';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import Testimonial from './Testimonials'
import Project from './Project'
import RecentArticles from './RecentArticle';
import Footer from './LastSection'

const OurFeature = () => {
  const [activeTab, setActiveTab] = useState('quote');

  const handleTabClick = (tabId) => {
    setActiveTab(tabId);
  };
  const features = [
    {
      icon: 'icon-wallet',
      title: 'Transparent Pricing',
      description: 'International supply chains involve challenging regulations.',
    },
    {
      icon: 'icon-search',
      title: 'Real-Time Tracking',
      description: 'Ensure customers’ supply chains are fully compliant by practices.',
    },
    {
      icon: 'icon-trolley',
      title: 'Warehouse Storage',
      description: 'Depending on your product, we provide warehouse activities.',
    },
    {
      icon: 'icon-package-6',
      title: 'Security For Cargo',
      description: 'High security requirements and are certified to local standards.',
    },
    {
      icon: 'icon-payment-method',
      title: 'Easy Payment Methods',
      description: 'You can make use the easy payment options, listed below.',
    },
    {
      icon: 'icon-delivery-4',
      title: 'Fast & Efficient Delivery',
      description: 'We enhance our operations by relieving you of the worries.',
    },
    {
      icon: 'icon-wishlist',
      title: 'Personalised solutions',
      description: 'We continue to pursue that same vision in today\'s complex solutions.',
    },
  ];
  const testimonials = [
    {
      authorThumb: require('../assets/images/testimonials/thumbs/1.jpg'),
      description: 'They are the best of the best, and expertly trained team members who take the extra step and go the extra mile, all to fulfill our dedicated promise to deliver innovative and dynamic solutions to our customers to fit the needs of a rapidly changing global environment.',
      signature: require('../assets/images/testimonials/signature.png'),
      author: 'The Move Inc'
    },
    {
      authorThumb: require('../assets/images/testimonials/thumbs/1.jpg'),
      description: 'Logisti team is the best of the best, and expertly trained team members who take the extra step and go the extra mile, all to fulfill our dedicated promise to deliver innovative and dynamic solutions to our customers to fit the needs of a rapidly changing global environment.',
      signature: require('../assets/images/testimonials/signature.png'),
      author: 'The Move Inc'
    },
  ];

  return (
    <>
    <section id="fancyboxCarousel" className="fancybox-layout4 fancybox-carousel bg-overlay bg-overlay-gradient-secondary-2" style={{backgroundColor: '#071039' }}>
      <div className="bg-img"><img src="assets/images/backgrounds/5.jpg" alt="background" /></div>
      <div className="container">
        <div className="row">
          <div className="col-sm-12 col-md-12 col-lg-6 offset-lg-3">
            <div className="heading text-center mb-40">
              <span className="heading__subtitle">Our Features</span>
              <h2 className="heading__title color-white">Why Choose Us!</h2>
              <p className="heading__desc" style={{color: '#9b9b9b'}}>We continue to pursue that same vision in today's complex, uncertain world, working every day to earn our customers’ trust!</p>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-12 col-md-12 col-lg-12">
            <OwlCarousel
              className="carousel owl-carousel carousel-arrows"
              items={1}
              slideBy={1}
              responsive={{
                0: {
                  items: 1, 
                  slideBy: 1,
                },
                576: {
                  items: 2,
                  slideBy: 2,
                },
                768: {
                  items: 3,
                  slideBy: 3,
                },
                992: {
                  items: 4, 
                  slideBy: 4,
                }
              }}
              autoplay
              nav
              dots={false}
              margin={20}
              loop
              autoplaySpeed={800}
            >
              {features.map((feature, index) => (
                <div className="fancybox-item" key={index}>
                  <div className="fancybox__icon">
                    <i className={feature.icon}></i>
                  </div>
                  <div className="fancybox__content">
                    <h4 className="fancybox__title">{feature.title}</h4>
                    <p className="fancybox__desc" style={{color: '#9b9b9b'}}>{feature.description}</p>
                  </div>
                </div>
              ))}
            </OwlCarousel>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-12 col-md-12 col-lg-12 text-center">
            <p className="text__link mb-0" style={{color: '#9b9b9b'}}>Working every day to earn our customers’ trust. <a href="#" className="color-theme">Get Started!</a></p>
          </div>
        </div>
      </div>
    </section>
    <section id="requestQuoteTabs" className="request-quote request-quote-tabs p-0">
      <div className="container">
        <div className="row">
          <div className="col-sm-12 col-md-12 col-lg-12">
            <div className="request__form">
              <nav className="nav nav-tabs">
                <a
                  className={`nav__link ${activeTab === 'quote' ? 'active' : ''}`}
                  onClick={() => handleTabClick('quote')}
                  href="#quote"
                >
                  Request A Quote
                </a>
                <a
                  className={`nav__link ${activeTab === 'track' ? 'active' : ''}`}
                  onClick={() => handleTabClick('track')}
                  href="#track"
                >
                  Track & Trace
                </a>
              </nav>
              <div className="tab-content">
                <div className={`tab-pane fade ${activeTab === 'quote' ? 'show active' : ''}`} id="quote">
                  <div className="request-quote-panel">
                    <div className="request__form-body">
                      <div className="row">
                        <div className="col-sm-12 col-md-12 col-lg-12">
                          <h6>Personal Data</h6>
                        </div>
                        <div className="col-sm-6 col-md-4 col-lg-4">
                          <div className="form-group">
                            <input type="text" className="form-control" placeholder="Name" />
                          </div>
                        </div>
                        <div className="col-sm-6 col-md-4 col-lg-4">
                          <div className="form-group">
                            <input type="email" className="form-control" placeholder="Email" />
                          </div>
                        </div>
                        <div className="col-sm-6 col-md-4 col-lg-4">
                          <div className="form-group">
                            <input type="text" className="form-control" placeholder="Phone" />
                          </div>
                        </div>
                        <div className="col-sm-12 col-md-12 col-lg-12">
                          <h6 className="mt-5">Shipment Data</h6>
                        </div>
                        <div className="col-sm-6 col-md-4 col-lg-4">
                          <div className="form-group form-group-select">
                            <select className="form-control">
                              <option>Freight Type</option>
                              <option>Freight Type 1</option>
                              <option>Freight Type 2</option>
                            </select>
                          </div>
                        </div>
                        <div className="col-sm-6 col-md-4 col-lg-4">
                          <div className="form-group">
                            <input type="text" className="form-control" placeholder="City of Departure" />
                          </div>
                        </div>
                        <div className="col-sm-6 col-md-4 col-lg-4">
                          <div className="form-group">
                            <input type="text" className="form-control" placeholder="Delivery City" />
                          </div>
                        </div>
                        <div className="col-sm-6 col-md-4 col-lg-4">
                          <div className="form-group form-group-select">
                            <select className="form-control">
                              <option>Incoterms</option>
                              <option>Incoterms 1</option>
                              <option>Incoterms 2</option>
                            </select>
                          </div>
                        </div>
                        <div className="col-sm-6 col-md-4 col-lg-4 d-flex">
                          <div className="form-group mr-20">
                            <input type="text" className="form-control" placeholder="Weight" />
                          </div>
                          <div className="form-group">
                            <input type="text" className="form-control" placeholder="Height" />
                          </div>
                        </div>
                        <div className="col-sm-6 col-md-4 col-lg-4 d-flex">
                          <div className="form-group mr-20">
                            <input type="text" className="form-control" placeholder="Width" />
                          </div>
                          <div className="form-group">
                            <input type="text" className="form-control" placeholder="Length" />
                          </div>
                        </div>
                        <div className="col-sm-12 col-md-12 col-lg-12 d-flex flex-wrap">
                          <div className="form-group input-radio">
                            <label className="label-radio">Fragile
                              <input type="radio" name="radioGroup2" defaultChecked />
                              <span className="radio-indicator"></span>
                            </label>
                          </div>
                          <div className="form-group input-radio">
                            <label className="label-radio">Express Delivery
                              <input type="radio" name="radioGroup2" />
                              <span className="radio-indicator"></span>
                            </label>
                          </div>
                          <div className="form-group input-radio">
                            <label className="label-radio">Insurance
                              <input type="radio" name="radioGroup2" />
                              <span className="radio-indicator"></span>
                            </label>
                          </div>
                          <div className="form-group input-radio">
                            <label className="label-radio">Packaging
                              <input type="radio" name="radioGroup2" />
                              <span className="radio-indicator"></span>
                            </label>
                          </div>
                        </div>
                        <div className="col-sm-12 col-md-12 col-lg-12">
                          <button className="btn btn__secondary btn__block">Request A Quote</button>
                        </div>
                      </div>
                    </div>
                    <div className="widget widget-download bg-theme">
                      <div className="widget__content">
                        <h5>Industry Solutions!</h5>
                        <p>Our worldwide presence ensures the timeliness, cost efficiency and compliance adherence
                          required to ensure your production timelines are met.</p>
                        <a href="#" className="btn btn__secondary btn__hover2 btn__block">
                          <span>Download 2024 Brochure</span><i className="icon-arrow-right"></i>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div className={`tab-pane fade ${activeTab === 'track' ? 'show active' : ''}`} id="track">
                  <div className="request-quote-panel">
                    <div className="request__form-body">
                      <div className="row">
                        <div className="col-sm-12 col-md-12 col-lg-12">
                          <div className="form-group">
                            <label>Shipment Type</label>
                            <div className="form-group form-group-select">
                              <select className="form-control">
                                <option>Packaging</option>
                                <option>Packaging 1</option>
                                <option>Packaging 2</option>
                              </select>
                            </div>
                          </div>
                        </div>
                        <div className="col-sm-12 col-md-12 col-lg-12">
                          <div className="form-group">
                            <label>Tracking Number</label>
                            <div className="form-group">
                              <textarea className="form-control" placeholder="You can enter up to a maximum of 10 airway bill numbers for tracking."></textarea>
                            </div>
                          </div>
                        </div>
                        <div className="col-sm-12 col-md-12 col-lg-12 d-flex flex-wrap">
                          <div className="form-group input-radio">
                            <label className="label-radio">Fragile
                              <input type="radio" name="radioGroup2" defaultChecked />
                              <span className="radio-indicator"></span>
                            </label>
                          </div>
                          <div className="form-group input-radio">
                            <label className="label-radio">Express Delivery
                              <input type="radio" name="radioGroup2" />
                              <span className="radio-indicator"></span>
                            </label>
                          </div>
                          <div className="form-group input-radio">
                            <label className="label-radio">Insurance
                              <input type="radio" name="radioGroup2" />
                              <span className="radio-indicator"></span>
                            </label>
                          </div>
                          <div className="form-group input-radio">
                            <label className="label-radio">Packaging
                              <input type="radio" name="radioGroup2" />
                              <span className="radio-indicator"></span>
                            </label>
                          </div>
                        </div>
                        <div className="col-sm-12 col-md-12 col-lg-12">
                          <button className="btn btn__secondary btn__block">Track & Trace</button>
                        </div>
                      </div>
                    </div>
                    <div className="widget widget-download bg-theme">
                      <div className="widget__content">
                        <h5>Industry Solutions!</h5>
                        <p>Our worldwide presence ensures the timeliness, cost efficiency and compliance adherence
                          required to ensure your production timelines are met.</p>
                        <a href="#" className="btn btn__secondary btn__hover2 btn__block">
                          <span>Download 2024 Brochure</span><i className="icon-arrow-right"></i>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section id="testimonial" className="testimonial testimonial-1 text-center pt-80 pb-70 test123">
      <div className="container">
        <div className="row">
          <div className="col-sm-12 col-md-12 col-lg-6 offset-lg-3">
            <div className="heading mb-50">
              <span className="heading__subtitle">What People Say!</span>
              <h2 className="heading__title">Testimonials</h2>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-12 col-md-12 col-lg-10 offset-lg-1">
            <OwlCarousel
              className="carousel owl-carousel carousel-arrows carousel-dots"
              items={1}
              slideBy={1}
              autoplay
              nav
              dots
              loop
              autoplaySpeed={800}
            >
              {testimonials.map((testimonial, index) => (
                <div className="testimonial-item" key={index}>
                  <div className="testimonial__thumb">
                    <img src={testimonial.authorThumb} alt="author thumb" />
                  </div>
                  <div className="testimonial__content">
                    <p className="testimonial__desc" style={{ color: "#9b9b9b" }}>{testimonial.description}</p>
                  </div>
                  <div className="testimonial__meta">
                    <img src={testimonial.signature} alt="signature" />
                    <p className="testimonial__meta-desc">{testimonial.author}</p>
                  </div>
                </div>
              ))}
            </OwlCarousel>
          </div>
        </div>
      </div>
    </section>
    <Testimonial></Testimonial>
    <Project></Project>
    <RecentArticles></RecentArticles>
    {/* <section id="contact1" class="contact contact-1 p-0">
      <div id="map"></div>
    <div className="container">
      <div className="contact-panel contact-panel-2">
        <div className="contact__panel-header d-flex align-items-center">
          <h4 className="contact__panel-title">Global Locations</h4>
        </div>
        <div id="accordion">
          <div className="accordion-item">
            <div className="accordion__item-header opened" data-toggle="collapse" data-target="#collapse1">
              <a className="accordion__item-title" href="#">Dubai Office</a>
            </div>
            <div id="collapse1" className="collapse show" data-parent="#accordion">
              <div className="accordion__item-body">
                <ul className="contact__list list-unstyled">
                  <li>002 010123456789</li>
                  <li>Email: info@gogroup.me</li>
                  <li>Address: Warehouse 7 Umm Ramool Behind dubai duty free, Dubai</li>
                  <li>Hours: Mon-Fri: 8am – 7pm</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    </section> */}
    <Footer/>
    </>
  );
};

export default OurFeature;
