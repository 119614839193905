import React from 'react';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import project1Image from '../assets/images/case-studies/grid/1.jpg';
import project2Image from '../assets/images/case-studies/grid/2.jpg';
import project3Image from '../assets/images/case-studies/grid/3.jpg';
import project4Image from '../assets/images/case-studies/grid/4.jpg';
import bannerImage from '../assets/images/banners/1.jpg';

const ProjectsCarousel = () => {
  const projects = [
    {
      img: project1Image,
      categories: ['Analystics', 'Optimization'],
      title: 'Lane Pairing Analysis',
      desc: 'We understand that data is the greatest asset when it comes to analyzing and optimizing your supply chain performance.',
      link: '#'
    },
    {
      img: project2Image,
      categories: ['Warehousing', 'Distribution'],
      title: 'Warehouse Hand Inventory',
      desc: 'We understand that data is the greatest asset when it comes to analyzing and optimizing your supply chain performance.',
      link: '#'
    },
    {
      img: project3Image,
      categories: ['Logistics', 'Analytics'],
      title: 'Minimize Cost Manufacturing',
      desc: 'Cost savings is crucial, innovative technology minimizes your overall spend by utilizing an extensive .',
      link: '#'
    },
    {
      img: project4Image,
      categories: ['Optimization', 'Warehousing'],
      title: 'Regulatory Compliance',
      desc: 'Cost savings is crucial, innovative technology minimizes your overall spend by utilizing an extensive .',
      link: '#'
    }
  ];
  const progressData = [
    { title: "Warehousing", percentage: 95 },
    { title: "Air Freight", percentage: 88 },
    { title: "Ocean Freight", percentage: 99 },
    { title: "Road Freight", percentage: 80 },
    { title: "Supply Chain", percentage: 70 }
  ];

  const renderProjects = () => {
    return projects.map((project, index) => (
      <div className="project-item" key={index}>
        <div className="project__img">
          <img src={project.img} alt="project img" />
          <div className="project__cat">
            {project.categories.map((category, i) => (
              <a href="#" key={i}>{category}</a>
            ))}
          </div>
        </div>
        <div className="project__content">
          <h4 className="project__title"><a href={project.link}>{project.title}</a></h4>
          <p className="project__desc" style={{color: '#9b9b9b'}}>{project.desc}</p>
          <a href={project.link} className="btn btn__link">
            <span>Read More</span><i className="icon-arrow-right"></i>
          </a>
        </div>
      </div>
    ));
  };

  return (
    <>
    <section id="projectsCarousel" className="projects-carousel">
      <div className="container">
        <div className="row">
          <div className="col-sm-12 col-md-12 col-lg-6 offset-lg-3">
            <div className="heading text-center mb-50">
              <span className="heading__subtitle">Latest Case Studies</span>
              <h2 className="heading__title">Featured Projects</h2>
              <p className="heading__desc" style={{color:'#9b9b9b'}}>We present the following case studies that exemplify the type of solutions and services we are providing across our customer base.</p>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-12 col-md-12 col-lg-12">
          <OwlCarousel className="carousel owl-carousel carousel-dots" items={window.innerWidth > 768 ? 3 : 1} autoplay={true} nav={false} dots={true} margin={30} loop={true} autoplaySpeed={1600}>
            {renderProjects()}
          </OwlCarousel>
          </div>
        </div>
      </div>
    </section>
    <section id="banner1" className="banner banner-1 p-0 bg-gray">
      <div className="container-fluid col-padding-0">
        <div className="row">
          <div className="col-sm-12 col-md-12 col-lg-6">
            <div className="inner-padding">
              <div className="heading heading-3 mb-30">
                <i className="icon-tag"></i>
                <span className="heading__subtitle">Affordable Price, Certified Forwarders</span>
                <h2 className="heading__title">Safe, Reliable & Express Logistic Solutions That Saves Your Time!</h2>
                <p className="heading__desc" style={{ color: '#9b9b9b'}}>We pride ourselves on providing the best transport and shipping services
                  available all over the world. Our skilled personnel, utilizing the latest communications, tracking and
                  processing software, combined with decades of experience.</p>
              </div>
              <a href="#" className="btn btn__primary">Schedule An Appointment</a>
            </div>
          </div>
          <div className="col-sm-12 col-md-12 col-lg-6 bg-overlay  background-banner">
            <div className="bg-img"><img src={bannerImage} alt="background" /></div>
            <div className="skills skills-panel">
              <div className="heading mb-30">
                <h2 className="heading__title">What We Achieved!</h2>
                <p className="heading__desc" style={{ color: '#9b9b9b'}}>Fulfill our dedicated promise to deliver innovative & dynamic solutions to our
                  customers to fit their needs.</p>
              </div>
              {/* Map through progress data */}
              {progressData.map((item, index) => (
                <div className="progress-item" key={index}>
                  <h6 className="progress__title">{item.title}</h6>
                  <span className="progress__percentage">{item.percentage}%</span>
                  <div className="progress">
                    <div
                      className="progress-bar"
                      style={{ width: `${item.percentage}%` }}
                      aria-valuenow={item.percentage}
                      aria-valuemin="0"
                      aria-valuemax="100"
                      role="progressbar"
                    ></div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>

    </>
  );
};

export default ProjectsCarousel;
