import React from 'react';
import Header from './Header';
import Footer from './LastSection';
import Country from './CountryCard';

const ContactSection = () => {
  return (
    <>
      <Header />

          <section id="contact3" className="contact contact-3 text-center container">
          <div className="row">
                <div className="col-sm-12 col-md-12 col-lg-12">
                  <div className="heading text-center mb-50">
                    <span className="heading__subtitle">Our Locations</span>
                    <h2 className="heading__title">Contact Us</h2>
                    <p className="heading__desc">We understand the importance of approaching each work integrally and believe in the power of simple and easy communication.</p>
                  </div>
                </div>
              </div>
         <div className='row'>
          <div className="col-sm-12 col-md-12 col-lg-4">
          <Country />
          </div>
          <div className="col-sm-12 col-md-12 col-lg-8">
            <div className="container">
              <div className="row">
                <div className="col-sm-12 col-md-12 col-lg-8 offset-lg-2">
                  <form>
                    <div className="row">
                      <div className="col-sm-6 col-md-6 col-lg-6">
                        <div className="form-group"><input type="text" className="form-control" placeholder="Name" /></div>
                      </div>
                      <div className="col-sm-6 col-md-6 col-lg-6">
                        <div className="form-group"><input type="email" className="form-control" placeholder="Email" /></div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-6 col-md-6 col-lg-6">
                        <div className="form-group"><input type="text" className="form-control" placeholder="Phone" /></div>
                      </div>
                      <div className="col-sm-6 col-md-6 col-lg-6">
                        <div className="form-group"><input type="text" className="form-control" placeholder="Company" /></div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-12 col-md-12 col-lg-12">
                        <div className="form-group">
                          <textarea className="form-control" placeholder="Request Details"></textarea>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-12 col-md-12 col-lg-6 offset-lg-3">
                        <button type="submit" className="btn btn__secondary btn__hover3 btn__block">Submit Request</button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
            </div>
            </div>
          </section>
      <Footer />
    </>
  );
};

export default ContactSection;
